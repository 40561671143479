/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #263238 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
  background-color: #ffffff; 
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #fff;
}
nav.navbar a.navbar-brand {
  width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #181818 !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded='true'] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #181818;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded='true'] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #181818;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded='true'] .navbar-toggler-icon {
  border-color: transparent;
}

@media (max-width: 992px) {
  .navbar-brand img {
    height: auto; 
    width: 90%; 
  }
}

@media (max-width: 768px) {
  .navbar-brand img {
    width: 90%; 
  }
}



/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex; 
  justify-content: center; 
  align-items: center; 
}

.banner h1 {
  font-size: 70px;
  font-weight: 600;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  color: #fff; 
  text-shadow: 2px 2px 4px #5fcd72; 
}

.banner p {
  color: #b8b8b8;
  font-size: 20px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.text-center {
  justify-content: center;
  align-items: center;
  text-align: center; 
  margin-bottom: 10vh;
}

@media only screen and (max-width: 767px) {
  .banner {
    padding: 190px 0 80px 0; 
  }

  .banner h1 {
    font-size: 40px; 
    margin-bottom: 10px; 

  }

  .banner p {
    font-size: 16px; 
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  
  .banner h1 {
    font-size: 60px;
    margin-bottom: 15px; 
  }

  .banner p {
    font-size: 18px;

  }
}


/************ About Css ************/ 
.about {
  padding: 0;
  position: relative;
  text-align: center;
}

.about-bx {
  background: rgba(38, 50, 56, 0.8) !important;
  border: 2px solid #fff !important;
  padding: 60px 20px; 
  text-align: left;
  max-width: 100%;
  color: #fff;
  margin-bottom: 10px;
}

.about h2 {
  font-size: 45px;
  font-weight: 700;
}

.about p {
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 0 0;
}

.content-wrapper {
  display: flex;
  flex-direction: column; 
}

.left-content,
.right-content {
  margin: 5px; 
}

.right-content {
  margin-top: 20px; 
}

/* Media Queries for Tablet */
@media (min-width: 768px) {
  .about-bx {
    padding: 60px 50px;
  }

  .content-wrapper {
    flex-direction: row; 
    align-items: center; 
  }

  .left-content {
    margin-right: 100px; 
  }
}




/************ Service Css ************/

.service {
  margin-top: 100px !important;
  height: auto; 
  /* background-image: url(./assets/img/bg-web3.png); */
}

.title-service {
  color: #fff;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 50px;
}
.service-card {
  margin-top :30px;
}

.service-sx {
  color: white;
  display: inline-flex;
  border: 3px solid white !important;
  padding-bottom: 80px;
  margin-right: auto;
  margin-left: auto;
  max-width: 300px;
  position: relative;
  margin-bottom: 50px;
  padding: 10px;
}

.service-sx::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5) !important;
  z-index: -1;
}

.service-img {
  width: 90px !important;
  margin-right: 10px; 
}

/* Media Queries for Tablets */
@media (max-width: 991.98px) {
  .service-sx {
    width: 100%; 
    max-width: none; 
  }

  .service-img {
    margin-right: 0; 
  }
}

/* Media Queries for Mobile */
@media (max-width: 767.98px) {
  .service-sx {
    width: 100%; 
    max-width: none; 
    flex-direction: column; 
    align-items: center; 
    text-align: center; 
  }

  .service-img {
    width: 50%; 
  }
}

/************ Product Css ************/ 
.product {
  background-color: #fff !important;
  margin-top: 100px !important;
  padding: 5px;
  height: 130vh;
}

.title-workflow {
  color: #181818;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 50px;
}
.title-product {
  color: #181818;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 50px;
}

.product-bx {
  width: 100% !important;
  height: 300px !important;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  margin-top: 50px !important;
}

.product-sx {
  color: #181818;
  display: inline-flex;
  border: 1px solid #181818 !important;
  padding-bottom: 80px !important;
  margin-right: auto; 
  margin-left: auto; 
  max-width: 300px; 
  position: relative; 
}

.product-img {
  width: 160px !important;
}

.workflow-img {
  width: 90px !important;
  margin-left: 50%;
  margin-top: 10%;
  margin-bottom: 10%;
}

@media (max-width: 768px) {
  .product {
    height: auto !important; 
    padding-top: 50px !important; 
  }

  .title-workflow {
    margin-top: 50px; 
  }

  .product-sx {
    width: 100% !important; 
    max-width: none !important; 
    margin-left: 10px !important; 
    margin-right: 10px !important; 
    margin-bottom: 20px !important; 
  }

  .workflow-img {
    width: 60px !important; 
    margin-left: auto !important; 
    margin-right: auto !important; 
    margin-top: 10px !important; 
    margin-bottom: 10px !important; 
  }
}

/* Tambahkan aturan CSS ini untuk layar dengan lebar maksimum 576px (mobile) */
@media (max-width: 576px) {
  .title-workflow {
    margin-top: 30px; 
    margin-bottom: 30px; 
  }

  .title-product {
    margin-top: 20px; 
    margin-bottom: 20px; 
  }

  .product-sx {
    width: 100% !important; 
    max-width: none !important;   
    margin-left: 10px !important; 
    margin-right: 10px !important; 
    margin-bottom: 20px !important; 
  }

  .product-bx {
    height: auto !important; 
    margin-top: 30px !important; 
    margin-bottom: 30px !important; 
  }

  .workflow-img {
    width: 50px !important; 
    margin-top: 5px !important; 
    margin-bottom: 5px !important; 
  }
}



/************ Solutions Css ************/

.title-sol{
  margin-bottom:50px;
  margin-top:50px !important;
}

.grp-sol {
  display: flex;
  flex-wrap: wrap; 
  justify-content: center; 
}
.solution-item {
  margin: 0 10px;
}

.h5r{
  padding: 20px 20px;
  margin-top: 30px !important;
}

.h5s{
  margin-top: 23px !important;
  padding: 20px 20px;

}

.solution-img {
  max-width: 100px;
  max-height: 100px;
  margin-bottom: 9px;
  margin-top: 9px;
}
.solution-imgs {
  max-width: 130px;
  max-height: 150px;
  margin-bottom: 9px;
  margin-top: 9px;
}
.solution-image {
  max-width: 110px;
  max-height: 160px;
  margin-bottom: 18px;
  margin-top: 9px;
}

h5 {
  border: 2px solid #ccc; 
  border-radius: 50px;
  padding: 10px 20px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-size: 15px !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  margin-top: 25px !important;
  width: 250px;
}

h5:hover {
  background-color: #e0e0e0;
  color: #000;
  border-color: #999;
}


/************ Client Css ************/
.client {
  background-color: #fff !important;
  margin-top: 100px !important;
  padding: 50px 0; 
  text-align: center; 
}

.our-cln {
  margin-top: 20px !important;
  color: #181818;
}

.client-img {
  width: 100px; 
  height: auto; 
  margin: 20px; 
}

/* Media Queries for Tablet */
@media (min-width: 768px) {
  .client-img {
    width: 150px; 
  }
}

/* Media Queries for Mobile */
@media (max-width: 576px) {
  .client-img {
    width: 100px; 
    margin: 10px; 
  }
}


/************ Contact Css ************/
.contact {
  background-image: linear-gradient(
  to right,
  #82f4b1,
  #75eba7,
  #67e29d,
  #59da93,
  #49d189,
  #39cc8c,
  #25c78f,
  #00c292,
  #00be9f,
  #00baaa,
  #00b5b3,
  #00b0b8
);  
  padding: 60px 0 90px 0;
  margin: 0;
  mask-image: linear-gradient(rgba(0, 0, 0, 10) 10%);
}
.contact img {
  width: 92%;
}
.contact h2 {
  font-size: 45px;
  font-weight: 700; 
  text-shadow: 2px 2px 4px #969B98;

}
.title{
  color: #181818;
}
.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: '';
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}


/************ Footer Css ************/
.footer {
  margin-top: 5vh;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer img {
  width: 26%;
}

.footer p {
  font-weight: 400;
  font-size: 17px;
  color: #fff;
  letter-spacing: 0.5px;
  text-align: left;
  margin-top: 20px;
}

.flex-container {
  display: flex;
  align-items: center;
}

.logo-img {
  margin-right: 10px;
  width: 26%;
}

.separator {
  border-right: 1px solid #fff;
  height: 50px;
  margin-right: 25px;
}

.flex-text {
  flex: 1;
  font-size: 20px;
  color: #fff;
}

.separator2 {
  content: '';
  display: block;
  width: 100%;
  border-bottom: 2px solid #fff;
}

.social-icon {
  display: flex;
  justify-content: flex-end;
  margin-right: -650px;
  margin-top: 10px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
  content: '';
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%)
    hue-rotate(346deg) brightness(95%) contrast(86%);
}

/* Tambahkan aturan CSS ini untuk layar dengan lebar maksimum 768px (tablet) */
@media (max-width: 768px) {
  .footer {
    padding: 20px; 
  }

  .footer img {
    width: 50%; 
  }

  .separator {
    display: none; 
  }

  .flex-text {
    font-size: 16px; 
  }

  .social-icon {
    justify-content: center; 
    margin-right: 0; 
    margin-top: 20px;
  }

  .social-icon a {
    margin-right: 10px; 
  }
}

/* Tambahkan aturan CSS ini untuk layar dengan lebar maksimum 576px (mobile) */
@media (max-width: 576px) {
  .footer img {
    width: 70%; 
    margin-bottom: 10px;
  }

  .flex-container {
    flex-direction: column; 
    align-items: center; 
    text-align: center; 
  }

  .separator2 {
    margin-top: 20px; 
  }

  .social {
    margin-top: 20px; 
  }
}
